import * as React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout/Layout'
import Appointments from '../../components/Appointments/Appointments'
import Button from '../../components/Button/Button'
import Text from '../../components/Text/Text'
import Underline from '../../components/Underline/Underline'
import Title from '../../components/Title/Title'
import TxtImg from '../../components/TxtImg/TxtImg'
import * as MassageStyles from './sports-remedial-massage.module.css'

function Massage({ data }) {
    return (
        <Layout>
            <Helmet>
                <title>Sports & Remedial Massage | Brixworth Osteopathic Clinic</title>
            </Helmet>
            <section className={MassageStyles.container}>
                <Title>
                    <h1>Sports & Remedial Massage</h1>
                    <h2>Maximise your body's performance and wellbeing with our advanced massage techniques.</h2>
                    <Button external={true} to="https://eubookings.nookal.com/bookings/location/GGNJC" type="primaryOutline">Book online today</Button>
                </Title>
            </section>
            <section>
                <TxtImg split="half" imgFirst={false} imgSrc={data.massage1} imgAlt="Deep tissue massage">
                    <Underline>
                        <h2>About Sports & Remedial Massage</h2>
                    </Underline>
                    <p>Many of the musculoskeletal issues we experience come about from our day-to-day lives. Our work, whether physically demanding or sedentary, brings a lot of postural problems and changes to our bodies.</p>
                    <p>Taking care of your body can help you reach optimal performance in your chosen sport or occupation or it can simply help you to move freely, without pain in whatever you chose to do.</p>
                    <p>At BOC we use a variety of advanced massage techniques including soft tissue release and muscle energy techniques, as well as postural and stretching advice to maximise the body’s performance and wellbeing.</p>
                </TxtImg>
            </section>
            <section className={MassageStyles.benefitsSection}>
                <Text>
                    <Underline>
                        <h2>What are the Benefits?</h2>
                    </Underline>
                    <p>The benefits of Sports, Remedial or Deep Tissue massage can be:</p>
                    <ul className={MassageStyles.benefitsList}>
                        <li>Pain reduction</li>
                        <li>Improved movement / range of motion</li>
                        <li>Relief from stress and tension</li>
                        <li>Increased circulation</li>
                        <li>Reduced scar tissue</li>
                        <li>Quicker healing from injury</li>
                        <li>Improved mood / emotional well-being</li>
                        <li>Improved posture</li>
                        <li>Injury prevention</li>
                        <li>To stimulate metabolic waste removal</li>
                    </ul>
                </Text>
            </section>
            <section>
                <Appointments fees="massage" />
            </section>
        </Layout>
    )
}

export const query = graphql`
    query {
        massage1: file(relativePath: {eq: "deep-tissue-massage.jpg"}) {
            childImageSharp {
                gatsbyImageData(
                    width: 800
                    placeholder: BLURRED
                )
            }
        }
    }
`

export default Massage